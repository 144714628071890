import { motion } from 'framer-motion';
import proj4 from 'proj4';
import { Fragment, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './map.module.scss';

const Map = ({ simple = false, data, pins = [], radius = 0.5 }) => {
  const { points, X_MIN, Y_MAX, X_RANGE, Y_RANGE, region, grid, width, height, ystep } = data;

  const [mapPoints, setMapPoints] = useState(points);
  const [geotags, setGeotags] = useState([]);
  const [active, setActive] = useState({});

  useEffect(() => {
    const drawPins = [];
    pins.forEach(pin => {
      const [googleX, googleY] = proj4(proj4.defs('GOOGLE'), [
        pin.coordinate[0],
        pin.coordinate[1],
      ]);

      let [rawX, rawY] = [
        (width * (googleX - X_MIN)) / X_RANGE,
        (height * (Y_MAX - googleY)) / Y_RANGE,
      ];

      const y = Math.round(rawY / ystep);
      if (y % 2 === 0) {
        rawX -= 0.5;
      }
      const x = Math.round(rawX);
      let [localx, localy] = [x, Math.round(y) * ystep];
      if (y % 2 === 0) {
        localx += 0.5;
      }

      const point = {
        x: localx,
        y: localy,
        svgOptions: { color: '#ffffff', radius: 0.8 },
        isPin: true,
        data: pin,
      };
      // allPoints[[x, y].join(';')] = point;
      drawPins.push(point);
    });

    // setMapPoints(allPoints);
    setGeotags(drawPins);
  }, [pins]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [geotags]);

  if (Object.values(mapPoints).length > 0) {
    return (
      <>
        {active.id && <Tooltip tooltip={active} handler={() => setActive({})} />}
        {simple && geotags.length > 0 && (
          <ReactTooltip
            className={styles.rtp}
            backgroundColor="#0b1d26"
            border={true}
            borderColor="#bca169"
          />
        )}
        <svg
          className={styles.geoMap}
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
          style={{ backgroundColor: 'transparent' }}
        >
          <>
            <g className={styles.dots}>
              {Object.values(mapPoints).map(({ x, y, svgOptions = {}, isPin }, k) => {
                const pointRadius = svgOptions.radius || 0.38;

                return (
                  <circle
                    key={`${x}${y}`}
                    cx={x}
                    cy={y}
                    r={pointRadius}
                    fill={svgOptions.color || '#BDBDBD'}
                  />
                );
              })}
            </g>
            {geotags.map(({ x, y }, k) => {
              return (
                <Fragment key={`${x}${y}`}>
                  <circle className={styles.circle1} cx={x} cy={y} r="3" />
                  <circle className={styles.circle2} cx={x} cy={y} r="2" />
                  <circle className={styles.circle3} cx={x} cy={y} r="1" />
                </Fragment>
              );
            })}
            {geotags.map(({ x, y, svgOptions = {}, data }, k) => {
              const pointRadius = svgOptions.radius || 0.38;
              return (
                <Fragment key={`${x}${y}`}>
                  <circle
                    className={styles.point}
                    cx={x}
                    cy={y}
                    r={pointRadius}
                    fill={svgOptions.color || '#646D71'}
                    stroke="#F8A362"
                    strokeWidth="0.3"
                    onMouseEnter={e =>
                      simple || setActive({ id: `${x}/${y}`, x: e.pageX, y: e.pageY, data })
                    }
                    onMouseLeave={() => simple || setActive({})}
                    data-tip={data.title}
                  />
                </Fragment>
              );
            })}
          </>
        </svg>
      </>
    );
  }

  return null;
};

const Tooltip = ({ tooltip, handler }) => {
  const ref = useRef();
  const [position, setPosition] = useState(null);
  useEffect(() => {
    const listener = event => {
      // console.log(ref.current, ref.current.contains(event.target));
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    const right = document.documentElement.clientWidth / 2 > tooltip.x;
    setPosition(right ? tooltip.x + 8 : tooltip.x - ref.current.clientWidth - 8);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);

  return (
    <motion.div
      ref={ref}
      className={styles.tooltip}
      style={{
        visibility: position ? 'visible' : 'hidden',
        left: position ? `${position}px` : `${tooltip.x + 8}px`,
        top: `${tooltip.y + 4}px`,
      }}
      initial={{ opacity: 0, y: '16px' }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className={styles.title}>{tooltip.data.title}</div>
    </motion.div>
  );
};

export default Map;
